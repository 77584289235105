import { call, put, takeLatest } from "redux-saga/effects";
import { URLS } from "../../../Constants/ApiUrls";
import { API_STATUS, METHOD } from "../../../Constants/AppConstants";
import {
  SET_USER_LOGIN,
  SET_USER_LOGOUT,
  USER_LOGIN,
  USER_LOGOUT,
} from "../../Actions/ActionTypes";
import ApiCall from "../../../AxiosHelper/APICall";

function* userLogin(action) {
  let data = action?.data;

  try {
    const result = yield call(() =>
      ApiCall({
        method: METHOD.HTTP.POST,
        url: URLS.LOGIN,
        requestBody: {
          username: data?.userEmail,
          password: data?.password,
        },
      })
    );
    let responseData = result?.response;
    // const response = result.json();
    if (result?.status === API_STATUS.SUCCESS) {
      yield put({
        type: SET_USER_LOGIN,
        data: { ...responseData.data },
      });
      action.callBack(result);
    } else {
      // yield put({
      //   type: SET_USER_LOGIN,
      //   data: { ...responseData.data?.data },
      // });
      action.callBack(result);
    }
  } catch (e) {
    action.callBack(e);
  }
}

function* userLogout(action) {
  try {
    const result = yield call(() =>
      ApiCall({
        method: METHOD.HTTP.POST,
        url: URLS.LOGOUT,
        requestBody: {},
      })
    );
    // let responseData = result?.response;
    // const response = result.json();
    if (result?.status === API_STATUS.SUCCESS) {
      yield put({
        type: SET_USER_LOGOUT,
      });
      action.callBack(result);
    } else {
      // yield put({
      //   type: SET_USER_LOGIN,
      //   data: { ...responseData.data?.data },
      // });
      action.callBack(result);
    }
  } catch (e) {
    action.callBack(e);
  }
}

export function* userWatcherSaga() {
  yield takeLatest(USER_LOGOUT, userLogout);
  yield takeLatest(USER_LOGIN, userLogin);
}
