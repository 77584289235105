import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export default function PrivateRoutes({ children }) {
  const authToken = useSelector((state) => state?.UserReducer?.token);
  return authToken === null || !authToken ? (
    <Navigate replace to="/" />
  ) : (
    <Outlet />
  );
}
