// import { applyMiddleware } from "@reduxjs/toolkit";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "./rootReducer";
import rootSagas from "./rootSagas";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
//create persisted store for getting data after page refresh or close
const persistConfig = {
  key: "UserReducer",
  storage: storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
// mount it on the Store
export const reduxStore = createStore(
  persistedReducer,
  applyMiddleware(sagaMiddleware)
);

export const persistor = persistStore(reduxStore);

// then run the saga
sagaMiddleware.run(rootSagas);
