import { call, takeLatest } from "redux-saga/effects";
import { URLS } from "../../../Constants/ApiUrls";
import { API_STATUS, METHOD } from "../../../Constants/AppConstants";
import ApiCall from "../../../AxiosHelper/APICall";

// Worker saga for handling GET request
function* getAdminSettings(action) {
  try {
    // Call the API function to get admin settings
    const response = yield call(() =>
      ApiCall({
        method: METHOD.HTTP.GET,
        url: URLS.CONFIGURATION,
      })
    );
    if (response?.status === API_STATUS.SUCCESS) {
      // Dispatch a success action with the received data
      action.callBack(response?.response?.data);
    } else {
      action.callBack(response);
    }
  } catch (error) {
    console.log("error: ", error);
    // Dispatch a failure action with the error message
  }
}

// Worker saga for handling POST request
function* saveAdminSettings(action) {
  try {
    // Call the API function to save admin settings
    const response = yield call(() =>
      ApiCall({
        method: METHOD.HTTP.PUT,
        url: URLS.CONFIGURATION,
        requestBody: action.data,
      })
    );
    // Dispatch a success action
    action.callBack(response?.response?.data);
  } catch (error) {
    console.log("error: ", error);
    // Dispatch a failure action with the error message
  }
}

// Watcher saga to listen for GET_ADMIN_SETTINGS and SAVE_ADMIN_SETTINGS actions
export function* adminSettingsWatcherSaga() {
  yield takeLatest("GET_CONFIGURATION", getAdminSettings);
  yield takeLatest("SET_CONFIGURATION", saveAdminSettings);
}
