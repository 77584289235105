const CustomPrimaryButton = ({
  btnLabel,
  onClick = null,
  isDisabled = false,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
      className={`[border:none] py-[18px] px-5 bg-mediumseagreen w-[180px] rounded-6xs h-[52px] flex flex-row items-center justify-center box-border min-w-[140px] hover:bg-mediumspringgreen Tablet:w-40 Mobile:self-stretch Mobile:w-full small_mobile:self-stretch small_mobile:w-auto ${
        isDisabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer "
      }`}
    >
      <b className="w-[129px] relative text-sm leading-[14px] uppercase inline-block font-heebo text-gray-200 text-center shrink-0">
        {btnLabel}
      </b>
    </button>
  );
};

export default CustomPrimaryButton;
