import { useNavigate } from "react-router-dom";

//Styles
import "./404Page.css";
import { STRINGS } from "../Utility/StringsEn";
import React from "react";

const ErrorPage = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // This navigates back in the history stack
  };

  return (
    <div className="error-page">
      <img src="./PLogo.png" alt="logo" />
      <div className="frame-parent4">
        <div className="frame-parent5">
          <img className="frame-child" alt="404error" src="./404image@2x.png" />
          <div className="label-parent">
            <b className="label11">{STRINGS.pageNotFound}</b>
            <div className="label12">{STRINGS.pageNotExists}</div>
          </div>
        </div>
        <div className="content3">
          <button className="buttonprimary4" onClick={goBack}>
            <b className="buttonlabel7">{STRINGS.goBack}</b>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
