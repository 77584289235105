import CustomSelect from "./CustomSelect";
import "./LabelAndCustomSelect.css";
const LabelAndCustomSelect = ({
  label,
  renderingOptions,
  fieldName,
  isFieldDisabled,
  onChange,
  value,
}) => {
  return (
    <div className="video-resolution">
      <div className="text-and-switch4">
        <div className="video-resolution1">
          <div className="max-meeting-time9">{label}</div>
        </div>
      </div>
      <CustomSelect
        fieldName={fieldName}
        isRequired
        isDisabled={isFieldDisabled}
        renderingOptions={renderingOptions}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default LabelAndCustomSelect;
