import { useMemo } from "react";
import "./PageHeading.css";

const PageHeading = ({ title, titleHeight, titleLineHeight }) => {
  const titleStyle = useMemo(() => {
    return {
      height: titleHeight,
      lineHeight: titleLineHeight,
    };
  }, [titleHeight, titleLineHeight]);

  return (
    <b className="title47" style={titleStyle}>
      {title}
    </b>
  );
};

export default PageHeading;
