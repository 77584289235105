import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Login from "./components/Login/Login";
import AdminSettings from "./components/Settings/AdminSettings";
import AdminDashboard from "./components/Dashboard/AdminDashboard";
import PrivateRoutes from "./components/PrivateRoutes/PrivateRoutes";
import ErrorPage from "./components/404Page/404Page";
import { ROUTES } from "./components/Constants/AppConstants";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  window.history.pushState(null, null, window.location.href);
  window.history.back();
  window.onpopstate = () => window.history.forward();
  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case ROUTES.settings:
        title = "";
        metaDescription = "";
        break;
      case ROUTES.dashboard:
        title = "";
        metaDescription = "";
        break;
      default:
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);
  const AppRoutes = [
    {
      path: ROUTES.settings,
      Component: AdminSettings,
    },
    {
      path: ROUTES.dashboard,
      Component: AdminDashboard,
    },
  ];
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      {AppRoutes.map((route, index) => {
        const { Component, ...rest } = route;
        return (
          <Route element={<PrivateRoutes {...rest} />} key={`route-${index}`}>
            <Route
              key={index}
              path={route?.path}
              element={<Component />}
              {...rest}
            />
          </Route>
        );
      })}
      <Route path="*" element={<ErrorPage />}></Route>
    </Routes>
  );
}
export default App;
