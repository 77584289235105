import { useState } from "react";

import MediaServerConatiner from "../../Dashboard/MediaServerInstances/MediaServerContainer/MediaServerContainer";
import TurnServerContainer from "../../Dashboard/MediaServerInstances/TurnServerContainer/TurnServerContainer";

import "./CustomTabs.css";
function CustomTabs() {
  const [value, setValue] = useState(0);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="tabs">
        <div className="inner-tab">
          <div
            className={`tab ${value === 0 ? "active" : ""}`}
            onClick={() => handleChange(0)}
          >
            <div className="media-server">Media Server</div>
          </div>
          <div
            className={`tab ${value === 1 ? "active" : ""}`}
            onClick={() => handleChange(1)}
          >
            <div className="turn-server">Turn Server</div>
          </div>
        </div>
      </div>
      <div className="tab-content">
        {value === 0 && <MediaServerConatiner />}
        {value === 1 && <TurnServerContainer />}
      </div>
    </>
  );
}

export default CustomTabs;
