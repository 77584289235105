import moment from "moment";

export const filterOptions = (array, key = "value", value) => {
  array.map((option) => {
    option.isSelected = option[key] === value;
    return option;
  });
  return array;
};

/**
 * Function to set given time to moment date and return timestamp
 * @param {Object} date moment js date object
 * @param {Object} time time object with hour, minute, second and millisecond
 * @returns {String} timestamp
 */
export function setGivenTimeToMomentDateAndGetMomentDate(date, time) {
  const { hour = 0, minute = 0, second = 0, millisecond = 0 } = time;

  // check if date is available
  if (!date) return "";

  // creating a new moment date with given time to avoid changing the original date
  const timestamp = date.format("x");
  const newMomentDate = moment(Number(timestamp));

  // set the time to new moment date and return timestamp
  return newMomentDate.set({
    h: hour,
    m: minute,
    s: second,
    ms: millisecond,
  });
}

/**
 * Function to convert date to given format
 * @param {Object} date moment js date object
 * @param {String} format format in which date is to be converted
 * Accepted formats are:
 * 1. DD/MM/YYYY
 * 2. DD/MM/YYYY HH:mm:ss
 * 3. DD/MM/YYYY HH:mm:ss.SSS
 * 4. DD/MM/YYYY HH:mm:ss.SSS Z
 * 5. MMM DD, YY (default)
 * 6. hh:mm A (12 hour format)
 * 7. HH:mm (24 hour format)
 * 8. hh:mm:ss A (12 hour format)
 * 9. HH:mm:ss (24 hour format)
 * TODO: add more formats
 * @returns moment js date object
 */
export function convertMomentDateToGivenFormat(
  date,
  format = " DD MMM YYYY, hh:mm A"
) {
  return date ? date.format(format) : "";
}

/**
 * Function to convert date string to moment date
 * @param {String} dateString Date string to be converted to moment date
 * @returns {Object} moment js date object
 */
export function convertDateStringToMomentDate(dateString) {
  return dateString ? moment(dateString) : "";
}
