import axios from "axios";
import { URLS } from "../Constants/ApiUrls";
import { reduxStore } from "../../components/Redux/store";
// Create a new instance of Axios
const instance = axios.create({
  baseURL: URLS.BASE_URL, // Set the base URL for all requests
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
  },
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    // You can modify the request config here
    // For example, you can add authentication headers
    const token = reduxStore.getState()?.UserReducer?.token;
    if (config.url !== URLS.LOGIN)
      config.headers["Authorization"] = `Bearer ${token}`;
      config.data = {
        ...config.requestBody,
      };
    return config;
  },
  (error) => Promise.reject(error)
);
// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // You can modify the response data here
    // For example, you can handle error responses globally
    return response;
  },
  (error) => {
    // Handle response error
    return Promise.reject(error);
  }
);

export default instance;
