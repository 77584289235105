import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./TopNavigation.css";
import { STRINGS } from "../../Utility/StringsEn";
import { ROUTES } from "../../Constants/AppConstants";

const TopNavigation = () => {
  const navigate = useNavigate();

  const onIcBackArrowClick = useCallback(() => {
    navigate(ROUTES.dashboard);
  }, [navigate]);

  return (
    <div className="title52">
      <button className="ic-back-arrow1" onClick={onIcBackArrowClick}>
        <img className="union-icon11" alt="" src="/union.svg" />
      </button>
      <b className="title53">{STRINGS.settings}</b>
    </div>
  );
};

export default TopNavigation;
