import StatCardsContainer from "../StatCardsContainer/StatCardsContainer";
import PropTypes from "prop-types";
import "./UsageStatisticsContainer.css";
import { STRINGS } from "../../../Utility/StringsEn";

const UsageStatisticsContainer = ({ className = "" }) => {
  return (
    <div className={`stats ${className}`}>
      <div className="title-parent">
        <b className="title48"> {STRINGS.usageStatistics}</b>
        {/* <div className="title-group">
          <div className="title49">Storage</div>
          <div className="frame-parent">
            <div className="title-wrapper">
              <div className="title49">30 GB</div>
            </div>
            <img className="sync-icon" alt="" src="/sync-icon.svg" />
          </div>
        </div> */}
      </div>
      <StatCardsContainer />
    </div>
  );
};

UsageStatisticsContainer.propTypes = {
  className: PropTypes.string,
};

export default UsageStatisticsContainer;
