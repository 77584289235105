import {
  GET_CONFIGURATION,
  SET_CONFIGURATION,
  SET_USER_LOGIN,
  USER_LOGIN,
  SET_USER_LOGOUT,
  USER_LOGOUT,
} from "./ActionTypes";

export const userLogin = (userData, callBack) => ({
  type: USER_LOGIN,
  data: userData,
  callBack,
});

export const setUserLogin = (userData) => ({
  type: SET_USER_LOGIN,
  data: userData,
});

export const userLogout = (callBack) => ({
  type: USER_LOGOUT,
  callBack,
});

export const setUserLogout = (userData) => ({
  type: SET_USER_LOGOUT,
  data: userData,
});

export const setConfiguration = (data, callBack) => ({
  type: SET_CONFIGURATION,
  data,
  callBack,
});

export const getConfiguration = (callBack) => ({
  type: GET_CONFIGURATION,
  callBack,
});
