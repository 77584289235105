import { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./DropdownOptions.css";
import { useDispatch } from "react-redux";
import { userLogout } from "../../Redux/Actions/UserActions";
import { STRINGS } from "../../Utility/StringsEn";
import { ROUTES } from "../../Constants/AppConstants";

const DropdownOptions = ({ onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onSettingClick = useCallback(() => {
    if (location.pathname !== ROUTES.settings) {
      navigate(ROUTES.settings);
    }
    onClose();
  }, [location.pathname, onClose, navigate]);

  const dispatch = useDispatch();
  const onLogout = () => {
    dispatch(
      userLogout(() => {
        navigate("/");
      })
    );
  };
  return (
    <div className="dropdown-options">
      <div
        className={`option-01 ${
          location.pathname === "/settings" ? "active-path" : ""
        }`}
        onClick={onSettingClick}
      >
        <b className="smart-jackpots-that1">{STRINGS.settings}</b>
      </div>
      <div className="divider" />
      <div className="option-01" onClick={onLogout}>
        <b className="smart-jackpots-that1">{STRINGS.logout}</b>
      </div>
    </div>
  );
};

export default DropdownOptions;
