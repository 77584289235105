import { createContext, useEffect, useState } from "react";
import CustomTabs from "../../CommonComponents/CustomTabs/CustomTabs";
import { STRINGS } from "../../Utility/StringsEn";
import "./MediaServerInstances.css";
import {
  convertDateStringToMomentDate,
  convertMomentDateToGivenFormat,
} from "../../Utility/Util";

export const ModifiedServerIntanceTableData = createContext();
const MediaServerInstances = ({ instanceData }) => {
  const [modifiedTableData, setModifiedTableData] = useState([]);

  const generateTableData = () => {
    instanceData &&
      Object.keys(instanceData).map((instanceServer) => {
        let modifiedData = instanceData[instanceServer].map(
          (serverData, index) => {
            return {
              id: index + 1,
              ip_address: serverData.ip_address,
              cpu_usage: serverData.cpu_usage,
              ram_usage: serverData.ram_usage,
              meetings: serverData.meetings,
              is_healthy: serverData.is_healthy
                ? STRINGS.healthy
                : STRINGS.unhealthy,
              status: serverData.status,
              created_at: convertMomentDateToGivenFormat(
                convertDateStringToMomentDate(serverData.created_at)
              ).replace(",", ", \n"),
              last_served_at: convertMomentDateToGivenFormat(
                convertDateStringToMomentDate(serverData.last_served_at)
              ).replace(",", ", \n"),
            };
          }
        );
        instanceData[instanceServer] = modifiedData;
        return instanceData[instanceServer];
      });
    setModifiedTableData({ ...instanceData });
  };

  useEffect(() => {
    generateTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceData]);

  return (
    <div className="media-server-instances">
      <div className="titleandsearch">
        <b className="title51"> {STRINGS.instances}</b>
      </div>
      <ModifiedServerIntanceTableData.Provider value={modifiedTableData}>
        <CustomTabs />
      </ModifiedServerIntanceTableData.Provider>
    </div>
  );
};

export default MediaServerInstances;
