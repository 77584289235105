import "./LoginForm.css";
import { useCallback, useState, useEffect } from "react";
import {
  TextField,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { API_STATUS, ROUTES } from "../../Constants/AppConstants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { userLogin, userLogout } from "../../Redux/Actions/UserActions";
import { STRINGS } from "../../Utility/StringsEn";

const LoginForm = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [loginForm, setLoginForm] = useState({ userEmail: "", password: "" });
  const [logoutHandled, setLogoutHandled] = useState(false);
  const location = useLocation();
  const authToken = useSelector((state) => state?.UserReducer?.token);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    if (location.pathname === "/" && !logoutHandled && authToken) {
      dispatch(
        userLogout(() => {
          console.log("User has been logged out.");
        })
      );
      navigate("/", { replace: true });
      setLogoutHandled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, navigate, logoutHandled, dispatch]);

  const onButtonSendClick = useCallback(
    (e) => {
      e.preventDefault();
      if (loginForm?.userEmail && loginForm?.password) {
        dispatch(
          userLogin(loginForm, (response) => {
            if (response?.status === API_STATUS.SUCCESS) {
              setIsLoginFailed(false);
              setErrorMsg("");
              navigate(ROUTES.dashboard);
            } else {
              setIsLoginFailed(true);
              setErrorMsg("Invalid credentials");
            }
          })
        );
      } else {
        setIsLoginFailed(true);
        setErrorMsg("Please enter Username and Password.");
      }
    },
    [loginForm, dispatch, navigate]
  );

  const handleOnChange = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
    setIsLoginFailed(false);
  };

  return (
    <form onSubmit={onButtonSendClick} className="fields2">
      <img className="logo-inside-icon" alt="" src="/logo@2x.png" />
      <b className="title60">{STRINGS.login}</b>
      <div className="type">
        <div className="hint-text">{STRINGS.email}</div>
        <TextField
          className="name1"
          color="primary"
          name="userEmail"
          size="small"
          placeholder={STRINGS.enterEmail}
          fullWidth={true}
          variant="outlined"
          sx={{ "& .MuiInputBase-root": { height: "40px" } }}
          onChange={handleOnChange}
          value={loginForm?.userEmail}
        />
      </div>
      <div className="type">
        <div className="hint-text">{STRINGS.password}</div>
        <OutlinedInput
          className="name1"
          color="primary"
          name="password"
          size="small"
          placeholder={STRINGS.enterPassword}
          sx={{
            "& .MuiInputBase-root": { height: "40px" },
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
          }}
          type={showPassword ? "text" : "password"}
          onChange={handleOnChange}
          value={loginForm?.password}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword} edge="end">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
      </div>
      <div className="button-frame">
        <button
          className="loginbtn"
          type="submit"
          disabled={!loginForm.userEmail || !loginForm.password}
        >
          <div className="smart-jackpots-that-you-may-lo-parent">
            <b className="smart-jackpots-that5">{STRINGS.loginButton}</b>
          </div>
        </button>
      </div>
      {isLoginFailed && <div className="error-message">{errorMsg}</div>}
    </form>
  );
};

export default LoginForm;
