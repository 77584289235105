import { useContext } from "react";
import { StatisticsContext } from "../../AdminDashboard";
import { STRINGS } from "../../../Utility/StringsEn";
import "./StatCardsContainer.css";
import moment from "moment";

const StatCardsContainer = () => {
  const contextData = useContext(StatisticsContext);
  const { awsEstimatedCost } = contextData || {};
  const { start, end } = awsEstimatedCost?.timePeriod || {};

  const formattedStart = start ? moment(start).format("DD MMM") : "";
  const formattedEnd = end ? moment(end).format("DD MMM") : "";
  const period =
    formattedStart && formattedEnd
      ? `(${formattedStart} - ${formattedEnd})`
      : "";

  return (
    <div className="card">
      <div className="stat">
        <div className="div1"> {contextData?.participents_count}</div>
        <div className="current-users">
          {contextData?.participents_count > 1
            ? STRINGS.currentUsers
            : STRINGS.currentUser}
        </div>
      </div>
      <div className="stat">
        <div className="wrapper">
          <div className="div1">{contextData?.sessions_count}</div>
        </div>
        <div className="current-users">
          {contextData?.sessions_count > 1
            ? STRINGS.numberOfMeetings
            : STRINGS.numberOfMeeting}
        </div>
      </div>
      <div className="stat">
        <div className="wrapper">
          <div className="div1">{contextData?.disk_usage}</div>
        </div>
        <div className="current-users">
          <p className="peak-load">{STRINGS.storage}</p>
        </div>
      </div>
      <div className="stat usage-cost">
        <div className="wrapper">
          <div className="div1">
            $ {contextData?.awsEstimatedCost?.amount || ""}
          </div>
        </div>
        <div className="current-users"> {`Usage Cost ${period}`}</div>
      </div>
    </div>
  );
};

export default StatCardsContainer;
