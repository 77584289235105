export const USER_LOGIN = "USER_LOGIN";
export const SET_USER_LOGIN = "SET_USER_LOGIN";

export const USER_LOGOUT = "USER_LOGOUT";
export const SET_USER_LOGOUT = "SET_USER_LOGOUT";

export const GET_CONFIGURATION = "GET_CONFIGURATION";
export const SET_CONFIGURATION = "SET_CONFIGURATION";

export const UPDATE_CONFIGURATION = "UPDATE_CONFIGURATION";

export const GET_STATISTICS = "GET_STATISTICS";
export const SET_STATISTICS = "SET_STATISTICS";

export const GET_INSTANCES = "GET_INSTANCES";
export const SET_INSTANCES = "SET_INSTANCES";

export const SET_PLAN = "SET_PLAN";

