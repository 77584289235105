import { useCallback } from "react";
import "./Footer.css";
import { STRINGS } from "../Utility/StringsEn";

const Footer = () => {

  return (
    <div className="footer2">
      <div className="content8">
        <div className="text-logo3">
          <div className="an-offering-from4">{STRINGS.anOfferingFrom}</div>
          <div className="springctcontact3">
            <div className="logo4">
              <img
                className="ic-logo-w-1-icon3"
                alt=""
                src="/ic-logo-w-1@2x.png"
              />
            </div>
          </div>
        </div>
        <div className="text6">
          <div className="spring-computing-technologies-container1">
            {`© 2024 Spring Computing Technologies, `}
            <span className="all-rights-reserved1">All Rights Reserved.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
