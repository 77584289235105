import { useState, useEffect, useRef } from "react";
import { Input, useToast } from "@chakra-ui/react";
import LabelAndCustomSelect from "../../../CommonComponents/CustomInputWithLabel/LabelAndCustomSelect";
import { STRINGS } from "../../../Utility/StringsEn";
import "./PerformanceTuningSection.css";

const PerformanceTuningSection = ({ renderingData, onChange }) => {
  const videoResolutionOptions = [
    {
      label: "High",
      value: "high",
    },
    {
      label: "Medium",
      value: "medium",
    },
    {
      label: "Low",
      value: "low",
    },
  ];
  const preferredCodecOptions = [
    {
      label: "AV1",
      value: "AV1",
    },
    {
      label: "VP9",
      value: "VP9",
    },
    {
      label: "VP8",
      value: "VP8",
    },
    {
      label: "H264",
      value: "H264",
    },
  ];
  const [isUnmounting, setIsUnmounted] = useState(false);
  const isUnmountingRef = useRef(isUnmounting);
  const toast = useToast();
  const [invalidFields, setInvalidFields] = useState({
    active_numberof_streams: false,
  });

  useEffect(() => {
    return () => {
      setIsUnmounted(!isUnmounting);
      isUnmountingRef.current = true;
      toast.closeAll();
    };
  }, []);

  const handleChange = (e, fieldName) => {
    const value = e.target.valueAsNumber;
    const isValid =
      value === "" || (Number(value) >= -1 && Number(value) !== 0);

    onChange(
      {
        ...renderingData,
        [fieldName]: Number(value) || "",
      },
      fieldName,
      isValid
    );
  };

  const validateChange = async (e, fieldName) => {
    await new Promise((r) => setTimeout(r, 200));
    if (isUnmountingRef.current) {
      e.preventDefault();
      return;
    }
    const value = e.target.valueAsNumber;
    const isValid =
      value === "" || (Number(value) >= -1 && Number(value) !== 0);

    if (!isValid) {
      toast({
        title: "Invalid value",
        description: `Active video streams must be a valid positive number or -1.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setInvalidFields({ ...invalidFields, [fieldName]: !isValid });
    onChange(
      {
        ...renderingData,
        [fieldName]: Number(value) || "",
      },
      fieldName,
      isValid
    );
  };

  return (
    <div className="performance-tuning">
      <b className="title57">Performance Tuning</b>
      <div className="optionframes1">
        <LabelAndCustomSelect
          label={STRINGS.videoResolution}
          renderingOptions={videoResolutionOptions}
          fieldName={"video_resolution"}
          onChange={(data) => {
            onChange({
              ...renderingData,
              video_resolution: data.target.value,
            });
          }}
          value={renderingData.video_resolution}
        />
        <LabelAndCustomSelect
          label={STRINGS.preferredCodec}
          renderingOptions={preferredCodecOptions}
          fieldName={"codec"}
          onChange={(data) => {
            onChange({
              ...renderingData,
              codec: data.target.value,
            });
          }}
          value={renderingData.codec}
        />
      </div>
      <div className="max-meeting-time11">
        <div className="text-and-switch6">
          <div className="video-resolution1">
            <div className="max-meeting-time10">Active Video Streams</div>
          </div>
        </div>
        <div className="fieldandinfo">
          <Input
            className="dropdown6"
            placeholder="Enter number"
            width="160px"
            w="160px"
            type="number"
            isInvalid={invalidFields.active_numberof_streams}
            errorBorderColor="red.300"
            value={renderingData.active_numberof_streams}
            onChange={(data) => handleChange(data, "active_numberof_streams")}
            onBlur={(data) => validateChange(data, "active_numberof_streams")}
          />
          <div className="hovericon">
            <img className="info-icon" alt="" src="/info.svg" />
            <div className="tooltip">
              <div className="setup-must-be">
                Default value is -1 (Means all available streams will be active)
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceTuningSection;
