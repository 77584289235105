import { useEffect, useState } from "react";
import Header from "../Header/Header";
import TopNavigation from "../CommonComponents/TopNavigation/TopNavigation";
import MeetingsParticipantsSection from "./Sections/MeetingsParticipantsSection/MeetingsParticipantsSection";
import PerformanceTuningSection from "./Sections/PerformanceTuningSection/PerformanceTuningSection";
import FeaturesContainer from "./Features/FeaturesContainer/FeaturesContainer";
import Footer from "../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import CustomPrimaryButton from "../CommonComponents/CustomButton/CustomPrimaryButton";
import {
  getAdminSettings,
  setAdminSettings,
} from "../Redux/Actions/AdminSettingsActions";
import { useToast } from "@chakra-ui/react";
import { STRINGS } from "../Utility/StringsEn";
import { useNavigate } from "react-router-dom";
import {
  USER_PLANS,
  FEATURES_LIST,
  ADVANCED_FEATURES_LIST,
} from "../Constants/AdminSettingsConstants";
import "./AdminSettings.css";
import { ROUTES } from "../Constants/AppConstants";

const AdminSettings = () => {
  const dispatch = useDispatch();
  const userPlan = useSelector((state) => state?.UserReducer?.plan);
  const navigate = useNavigate();
  const [oldConfig, setOldConfig] = useState(null);
  const [configData, setConfigData] = useState(null);
  const [invalidFields, setInvalidFields] = useState([]);
  const [isSettingsChanged, setSettingsChanged] = useState(false);
  const toast = useToast();

  useEffect(() => {
    dispatch(
      getAdminSettings((data) => {
        setConfigData(data?.configurations);
        setOldConfig(data?.configurations);
      })
    );
  }, [dispatch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkForSettingChanges = () => {
    const isSettingsSame =
      JSON.stringify(oldConfig) === JSON.stringify(configData);
    setSettingsChanged(!isSettingsSame);
  };

  useEffect(() => {
    checkForSettingChanges();
  }, [checkForSettingChanges, configData]);

  const validateField = (fieldName, isValid) => {
    if (isValid) {
      if (invalidFields.includes(fieldName)) {
        setInvalidFields(invalidFields.filter((f) => f !== fieldName));
      }
    } else {
      if (!invalidFields.includes(fieldName)) {
        setInvalidFields([...invalidFields, fieldName]);
      }
    }
  };

  const setConfiguration = () => {
    const configurations = {
      ...configData,
      quality_of_service: {
        ...configData.quality_of_service,
        active_numberof_streams:
          configData.quality_of_service.active_numberof_streams || -1,
      },
    };
    delete configurations.features.active_numberof_streams;
    dispatch(
      setAdminSettings({ configurations }, (response) => {
        if (response?.code === 200) {
          navigate(ROUTES.dashboard);
          toast({
            title: STRINGS.settingsAppliedSuccessfully,
            status: "success",
            variant: "subtle",
            duration: 3000,
            isClosable: true,
            onCloseComplete: () => {},
          });
        } else {
          toast({
            title: response.error.message,
            status: "error",
            variant: "subtle",
            duration: 3000,
            isClosable: true,
          });
        }
      })
    );
  };
  return (
    <div className="settings">
      <div className="top1">
        <Header />
        <div className="admin">
          <TopNavigation />

          <div className="white-bg">
            {configData && (
              <>
                <MeetingsParticipantsSection
                  onChange={(data, fieldName, _isValid) => {
                    setConfigData((prevState) => ({
                      ...prevState,
                      meeting_and_participants: data,
                    }));
                    validateField(fieldName, _isValid);
                  }}
                  renderingData={configData?.meeting_and_participants}
                />
                {/* {USER_PLANS.ENTERPRISE === userPlan &&
                configData?.advance_features && (
                  <AutoScalingServersSection
                    onChange={(data) =>
                      setConfigData((prevState) => ({
                        ...prevState,
                        auto_scaling: data,
                      }))
                    }
                    renderingData={configData?.auto_scaling}
                  />
                )} */}
                <PerformanceTuningSection
                  onChange={(data, fieldName, _isValid) => {
                    setConfigData((prevState) => ({
                      ...prevState,
                      quality_of_service: data,
                    }));
                    if (fieldName) {
                      validateField(fieldName, _isValid);
                    }
                  }}
                  renderingData={configData?.quality_of_service}
                />
                <div className="cf">
                  <FeaturesContainer
                    title={STRINGS.features}
                    featureList={FEATURES_LIST}
                    renderingData={configData?.features}
                    dependentFeatures={["waiting_room"]}
                    dependentOn={configData?.features?.moderator_control}
                    onChange={(data) => {
                      // if transcript is not selected then disable call_summary and catchup_meeting
                      if (data.hasOwnProperty("transcript")) {
                        if (!data.transcript) {
                          setConfigData((prevState) => {
                            return {
                              ...prevState,
                              advance_features: {
                                ...prevState.advance_features,
                                catchup_meeting: false,
                              },
                              features: {
                                ...prevState.features,
                                call_summary: false,
                              },
                            };
                          });
                        }
                      }
                      if (data.hasOwnProperty("moderator_control")) {
                        if (!data.moderator_control) {
                          setConfigData((prevState) => {
                            return {
                              ...prevState,
                              features: {
                                ...prevState.features,
                                waiting_room: false,
                              },
                            };
                          });
                        }
                      }
                      setConfigData((prevState) => {
                        return {
                          ...prevState,
                          features: {
                            ...prevState.features,
                            ...data,
                          },
                        };
                      });
                    }}
                  />
                  {USER_PLANS.ENTERPRISE === userPlan &&
                    configData?.advance_features && (
                      <FeaturesContainer
                        title={STRINGS.advanceFeatures}
                        featureList={ADVANCED_FEATURES_LIST}
                        renderingData={configData?.advance_features}
                        dependentFeatures={["catchup_meeting"]}
                        dependentOn={configData?.features?.transcript}
                        onChange={(data) => {
                          setConfigData((prevState) => {
                            return {
                              ...prevState,
                              advance_features: {
                                ...prevState.advance_features,
                                ...data,
                              },
                            };
                          });
                        }}
                      />
                    )}
                </div>
                <div className="self-stretch flex justify-end align-end">
                  <CustomPrimaryButton
                    btnLabel={STRINGS.applyButton}
                    onClick={setConfiguration}
                    isDisabled={invalidFields.length || !isSettingsChanged}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminSettings;
