import { createContext, useEffect, useState, useCallback } from "react";
import Header from "../Header/Header";
import PageHeading from "../CommonComponents/TopNavigation/PageHeading/PageHeading";
import UsageStatisticsContainer from "./Statistics/UsageStatisticsContainer/UsageStatisticsContainer";
import MediaServerInstances from "./MediaServerInstances/MediaServerInstances";
import Footer from "../Footer/Footer";
import { useDispatch } from "react-redux";
import { getInstances, getStatistics } from "../Redux/Actions/DashboardActions";
import { STRINGS } from "../Utility/StringsEn";
import IconButton from "@mui/material/IconButton";

import "./AdminDashboard.css";
export const StatisticsContext = createContext();

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const [statisticsData, setStatisticsData] = useState(null);
  const [instanceData, setInstanceData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    await Promise.all([
      new Promise((resolve) =>
        dispatch(
          getStatistics((response) => {
            setStatisticsData(response);
            resolve();
          })
        )
      ),
      new Promise((resolve) =>
        dispatch(
          getInstances((response) => {
            setInstanceData(response?.data);
            resolve();
          })
        )
      ),
    ]);
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="dashboard">
      <div className="top">
        <Header />
        <div className="body">
          <div className="title">
            <PageHeading title={STRINGS.dashboard} />
            <IconButton onClick={fetchData}>
              <img
                className={loading ? "spin" : ""}
                alt=""
                src="/sync-icon.svg"
              />
            </IconButton>
          </div>
          <StatisticsContext.Provider value={statisticsData}>
            <UsageStatisticsContainer getLatestStatisticsData={fetchData} />
          </StatisticsContext.Provider>
          <MediaServerInstances instanceData={instanceData} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminDashboard;
