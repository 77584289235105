export const STRINGS = {
  login: "Login",
  register: "Register",
  forgotPassword: "Forgot Password",
  resetPassword: "Reset Password",
  dashboard: "Dashboard",
  usageStatistics: "Usage Statistics",
  instances: "Instances",

  // BUTTON LABELS
  loginButton: "LOGIN",
  applyButton: "APPLY",
  mediaServer: "Media Server",
  turnServer: "Turn Server",

  // PLACEHOLDER TEXTS
  search: "Search..",
  enterEmail: "Enter Email Address",
  enterPassword: "Enter Password",

  // LABELS
  userName: "Username",
  password: "Password",
  email: "Email ID",
  confirmPassword: "Confirm Password",
  settings: "Settings",
  logout: "Logout",
  currentUser: "Current User",
  currentUsers: "Current Users",
  numberOfMeeting: "Number of Meeting",
  numberOfMeetings: "Number of Meetings",
  peakLoad: "Peak Load",
  concurrentMeetings: "Concurrent Meetings",
  bandwidth: "Bandwidth",
  consumptionPerDay: "Consumption/Day",
  usageCost: "Usage Cost",
  storage: "Storage",
  gb: "GB",
  mb: "MB",
  srNo: "Sr. No.",
  ip: "IP",
  cpu: "CPU",
  memory: "Memory",
  meetings: "Meetings",
  remainingCapacity: "Remaining Capacity",
  healthCheck: "Health Check",
  status: "Status",
  runTime: "Run Time",
  creationTime: "Creation Time",
  lastServedAt: "Last Served At",
  maxMeetingTimes: "Max Meeting Time",
  numberOfParticipants: "Max Participants",
  autoScaling: "Auto Scaling",
  numberOfServers: "Number of Servers",
  qualityOfService: "Quality of Service",
  videoResolution: "Video Resolution",
  preferredCodec: "Preferred Codec",
  activeVideoStreams: "Active Video Streams",
  features: "Features",
  advanceFeatures: "Advance Features",
  audioCalls: "Audio Calls",
  videoCalls: "Video Calls",
  liveChat: "Live Chat",
  clientSideRecording: "Client Side Recording",
  serverSideRecording: "Server Side Recording",
  waitingRoom: "Waiting Room",
  screenSharing: "Screen Sharing",
  desktopStreaming: "Desktop Streaming",
  moderatorControl: "Moderator Control",
  transcript: "Transcript",
  autoCentering: "Auto-Centering",
  callSummary: "Call Summary",
  catchUpMeeting: "Catch-Up Meeting",
  pipScreenSharing: "PIP Screen Sharing",
  enterpriseEdition: "Enterprise Edition",

  audioCallDescription:
    "Crystal-clear voice communication for seamless discussions",
  videoCallDescription:
    "High-definition video streaming with adaptive quality adjustments to ensure optimal performance even in varying network conditions",
  screenSharingDescription:
    "Participants can share their screens to present documents, slides, or applications",
  clientSideRecordingDescription:
    "Capture important discussions for future reference and review",
  moderatorControlDescription:
    "Manage participants, mute/unmute, and control access for smooth conduct of meetings",
  waitingRoomDescription:
    "Manage participants before entering the main meeting for organized sessions (Moderator Control feature must be enable to use this feature)",
  transcriptDescription: "Automatically generate transcripts",
  callSummaryDescription:
    "Summarize key points, and record action items for efficient follow-up after a meeting (Transcript feature must be enable to use this feature)",
  catchUpMeetingDescription:
    "Enable participants who join late to access a summary of the call's progress and discussion points (Transcript feature must be enable to use this feature)",

  // FOOTER
  anOfferingFrom: "An offering from",
  copyrightText: "© {year} Spring Computing Technologies, All Rights Reserved.",
  privacyPolicy: "Privacy Policy",

  // ERROR MESSAGES
  // SUCCESS MESSAGES
  settingsAppliedSuccessfully: "Settings applied successfully.",
  // INFO MESSAGES

  // MISC
  hhmm: "hh:mm",
  noDataFound: "No Data Found",
  healthy: "Healthy",
  unhealthy: "Unhealthy",

  pageNotFound: "Page Not Found",
  pageNotExists: "Oops! The page you are looking for does not exists",
  goBack: "Go Back",
};
