import LoginForm from "./LoginForm/LoginForm";
import "./Login.css";

const Login = () => {
  return (
    <div className="index">
      <div className="login-inner">
        <div className="graphics">
          <img className="image-icon" alt="" src="/frame-1261152679@2x.png" />
        </div>
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
