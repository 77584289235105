import { GET_CONFIGURATION, SET_CONFIGURATION } from "../Actions/ActionTypes";

// Import the necessary action types

// Getter action
export const getAdminSettings = (callBack) => {
  return {
    type: GET_CONFIGURATION,
    callBack,
  };
};

// Setter action
export const setAdminSettings = (settings, callBack) => {
  return {
    type: SET_CONFIGURATION,
    data: settings,
    callBack,
  };
};
