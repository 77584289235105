import { Select } from "@chakra-ui/react";

const CustomSelect = ({
  fieldName,
  isRequired,
  renderingOptions,
  isDisabled,
  onChange,
  value,
}) => {
  const keyGenerator = (label, index) => {
    return `${fieldName.toLowerCase().replace(" ", "-")}_${label}_${index}`;
  };
  return (
    <div className="custom-select">
      <Select
        name={fieldName}
        required={isRequired}
        disabled={isDisabled}
        onChange={onChange}
        defaultValue={value}
      >
        {renderingOptions?.map((option, index) => {
          return (
            <option
              key={keyGenerator(option.value, index)}
              value={option.value}
            >
              {option.label}
            </option>
          );
        })}
      </Select>
    </div>
  );
};

export default CustomSelect;
