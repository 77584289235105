import { Switch } from "@chakra-ui/react";
import { STRINGS } from "../../../Utility/StringsEn";
import "./FeatureCard.css";

const FeatureCard = ({
  icon,
  label,
  isChecked,
  onChange,
  isDisabled,
  name,
  description,
  isAdvance = false,
}) => {
  return (
    <div className="featurecard1">
      <div className="iconandtext1">
        <img className="iconaudiocalls" alt="" src={icon} />
        <div className="textfeature1">
          <div className="video-calls1">
            <p>{label}</p>
            {isAdvance && (
              <p className="video-calls1">({STRINGS.enterpriseEdition})</p>
            )}
          </div>
          <div className="hovericon1">
            <img className="info-icon1" alt="" src="/info.svg" />
            <div className="tooltip1">
              <div className="crystal-clear-voice-communicat44">
                {description}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Switch
        colorScheme="aa"
        isChecked={isChecked}
        isDisabled={isDisabled}
        onChange={onChange}
        name={name}
      />
    </div>
  );
};

export default FeatureCard;
