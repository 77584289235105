import { GET_INSTANCES, GET_STATISTICS } from "../Actions/ActionTypes";

// Getter action
export const getStatistics = (callBack) => {
  return {
    type: GET_STATISTICS,
    callBack,
  };
};

// get instances
export const getInstances = (callBack) => {
  return {
    type: GET_INSTANCES,
    callBack,
  };
};
