import { useState, useCallback, useRef } from "react";
import DropdownOptions from "../CommonComponents/CustomDropdown/DropdownOptions";
import PortalPopup from "../CommonComponents/PortalPopup/PortalPopup";
import "./Header.css";

const Header = () => {
  const [isDropdownOptionsPopupOpen, setDropdownOptionsPopupOpen] =
    useState(false);
  const parentRef = useRef(null);

  const openDropdownOptionsPopup = useCallback(() => {
    setDropdownOptionsPopupOpen(true);
  }, []);

  const closeDropdownOptionsPopup = useCallback(() => {
    setDropdownOptionsPopupOpen(false);
  }, []);

  return (
    <>
      <div className="header">
        <div className="proconf-logo-header-parent">
          <img
            className="proconf-logo-header"
            alt=""
            src="/proconf-logo-header@2x.png"
          />
          <div
            className="frame-parent"
            ref={parentRef}
            onClick={openDropdownOptionsPopup}
          >
            <div className="name-and-post-parent">
              <div className="name-and-post">
                <div className="admin-name">ProConf Admin</div>
                <div className="admin1">Admin</div>
              </div>
              <img className="photo-icon" alt="" src="/photo@2x.png" />
            </div>
            <div className="dropdown" id="dropdown">
              <img className="vector-icon6" alt="" src="/vector1.svg" />
            </div>
            {isDropdownOptionsPopupOpen && (
              <PortalPopup
                placement="Bottom right"
                onOutsideClick={closeDropdownOptionsPopup}
                relativeLayerRef={parentRef}
              >
                <DropdownOptions onClose={closeDropdownOptionsPopup} />
              </PortalPopup>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
