import AxiosHelper from "./AxiosHelper";
import { API_STATUS } from "../Constants/AppConstants";

export default function ApiCall(requestObject) {
  return AxiosHelper(requestObject)
    .then((response) => {
      return {
        status: API_STATUS.SUCCESS,
        response: response.data,
        responseHeaders: response.headers,
      };
    })
    .catch((error) => {
      return error;
    });
}
