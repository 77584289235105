import { call, put, takeLatest } from "redux-saga/effects";
import { URLS } from "../../../Constants/ApiUrls";
import { API_STATUS, METHOD } from "../../../Constants/AppConstants";
import ApiCall from "../../../AxiosHelper/APICall";
import { SET_PLAN } from "../../Actions/ActionTypes";

// Worker saga for handling GET request
function* getDashboardStaticstics(action) {
  try {
    // Call the API function to get admin settings
    const response = yield call(() =>
      ApiCall({
        method: METHOD.HTTP.GET,
        url: URLS.STATISTICS,
      })
    );
    if (response?.status === API_STATUS.SUCCESS) {
      yield put({
        type: SET_PLAN,
        data: { ...response?.response?.data },
      });
      // Dispatch a success action with the received data
      action.callBack(response?.response?.data);
    } else {
      action.callBack(response);
    }
  } catch (error) {
    console.log("error: ", error);
    // Dispatch a failure action with the error message
  }
}

function* getDashboardInstances(action) {
  try {
    // Call the API function to get admin settings
    const response = yield call(() =>
      ApiCall({
        method: METHOD.HTTP.GET,
        url: URLS.INSTANCES,
      })
    );
    if (response?.status === API_STATUS.SUCCESS) {
      // Dispatch a success action with the received data
      action.callBack(response?.response);
    } else {
      action.callBack(response);
    }
  } catch (error) {
    console.log("error: ", error);
    // Dispatch a failure action with the error message
  }
}
// Watcher saga to listen for GET_ADMIN_SETTINGS and SAVE_ADMIN_SETTINGS actions
export function* adminStatisticsWatcherSaga() {
  yield takeLatest("GET_STATISTICS", getDashboardStaticstics);
  yield takeLatest("GET_INSTANCES", getDashboardInstances);
}
